/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Quill now has an offical Content Distribution Network so you can have access to a reliable, high-speed host for the library. To include a file:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<link rel=\"stylesheet\" href=\"//cdn.quilljs.com/0.16.0/quill.snow.css\" />\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<script src=\"//cdn.quilljs.com/0.16.0/quill.min.js\"></script>\n")), "\n", React.createElement(_components.p, null, "You can also use \"latest\" as the version:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<script src=\"//cdn.quilljs.com/latest/quill.min.js\"></script>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
